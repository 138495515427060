

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="text-red-600">
          Customer support       
         </p>
      </header>
    </div>
  );
}

export default App;
